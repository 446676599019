import { LoginStore } from "./LoginStore";
import { TabStore } from "./TabStore";
import { NotificationStore } from "./NotificationStore";
import { SpaceGridStore } from "./SpaceGridStore";
import { LookupStore } from "./LookupStore";
import { UserDetailsStore } from "./UserDetailsStore";
import { ClientsGridStore } from "./ClientsGridStore";
import { AddOrEditSpaceStore } from "./AddOrEditSpaceStore";
import { AddOrEditClientStore } from "./AddOrEditClientStore";
import { ClientDetailsStore } from "./ClientDetailsStore";
import { SpaceDetailsStore } from "./SpaceDetailsStore";
import { RoomDetailsStore } from "./RoomDetailsStore";
import { CountriesAndCitiesStore } from "./CountriesAndCitiesStore";
import { AddOrEditRoomStore } from "./AddOrEditRoomStore";
import { HotdeskBookingsGridStore } from "./HotdeskBookingsGridStore";
import { RoomBookingsGridStore } from "./RoomBookingsGridStore";
import { ManageAccountStore } from "./ManageAccountsStore";
import { AddOrEditHotdeskBookingStore } from "./AddOrEditHotdeskBookingStore";
import { UsersGridStore } from "./UsersGridStore";
import { AddOrEditRoomBookingStore } from "./AddOrEditRoomBookingStore";
import { AddOrEditOfficeStore } from "./AddOrEditOfficeStore";
import { TeamsStore } from "./TeamsStore";
import { SubscriptionStore } from "./SubscriptionStore";
import { BenefitsStore } from "./BenefitsStore";
import { BenefitHistoryStore } from "./BenefitHistoryStore";
import { ClientOfficesStore } from "./ClientOfficesStore";
import { AddOrEditTeamStore } from "./AddOrEditTeamStore";
import { AddOrEditBenefitStore } from "./AddOrEditBenefitStore";
import { AdminAccountsStore } from "./AdminAccountsStore";
import { AdminLogsStore } from "./AdminLogsStore";
import { ChangeHotdeskBookingPricesStore } from "./UpdateSpacePrices";

export type StateDictionary<T> = { [key: string]: T };

// The top-level state object
export interface ApplicationState {
    login: LoginStore.IState;
    notification: NotificationStore.IState;
    lookup: LookupStore.IState;

    tab: StateDictionary<TabStore.IState>;
    users: StateDictionary<UsersGridStore.IState>;

    hotdeskBookingsGrid: StateDictionary<HotdeskBookingsGridStore.IState>;
    roomBookingsGrid: StateDictionary<RoomBookingsGridStore.IState>;

    spaceGrid: SpaceGridStore.IState;
    userDetails: UserDetailsStore.IState;
    clients: ClientsGridStore.IState;
    addOrEditSpace: AddOrEditSpaceStore.IState;
    addOrEditClient: AddOrEditClientStore.IState;
    clientDetails: ClientDetailsStore.IState;
    spaceDetails: SpaceDetailsStore.IState;
    roomDetails: RoomDetailsStore.IState;
    countriesAndCities: CountriesAndCitiesStore.IState;
    addOrEditRoom: AddOrEditRoomStore.IState;
    manageAccounts: ManageAccountStore.IState;
    addOrEditHotdeskBooking: AddOrEditHotdeskBookingStore.IState;
    addOrEditRoomBooking: AddOrEditRoomBookingStore.IState;
    addOrEditOffice: AddOrEditOfficeStore.IState;
    teams: TeamsStore.IState;
    subscription: SubscriptionStore.IState;
    benefits: BenefitsStore.IState;
    benefitHistory: BenefitHistoryStore.IState;
    clientOffices: ClientOfficesStore.IState;
    addOrEditTeam: AddOrEditTeamStore.IState;
    addOrEditBenefit: AddOrEditBenefitStore.IState;
    adminAccounts: AdminAccountsStore.IState;
    adminLogs: AdminLogsStore.IState;
    changeHotdeskBookingPrices: ChangeHotdeskBookingPricesStore.IState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    login: LoginStore.reducer,
    tab: TabStore.reducer,
    notification: NotificationStore.reducer,
    spaceGrid: SpaceGridStore.reducer,
    lookup: LookupStore.reducer,
    userDetails: UserDetailsStore.reducer,
    clients: ClientsGridStore.reducer,
    addOrEditSpace: AddOrEditSpaceStore.reducer,
    addOrEditClient: AddOrEditClientStore.reducer,
    clientDetails: ClientDetailsStore.reducer,
    spaceDetails: SpaceDetailsStore.reducer,
    roomDetails: RoomDetailsStore.reducer,
    countriesAndCities: CountriesAndCitiesStore.reducer,
    addOrEditRoom: AddOrEditRoomStore.reducer,
    hotdeskBookingsGrid: HotdeskBookingsGridStore.reducer,
    roomBookingsGrid: RoomBookingsGridStore.reducer,
    manageAccounts: ManageAccountStore.reducer,
    addOrEditHotdeskBooking: AddOrEditHotdeskBookingStore.reducer,
    users: UsersGridStore.reducer,
    addOrEditRoomBooking: AddOrEditRoomBookingStore.reducer,
    addOrEditOffice: AddOrEditOfficeStore.reducer,
    teams: TeamsStore.reducer,
    subscription: SubscriptionStore.reducer,
    benefits: BenefitsStore.reducer,
    benefitHistory: BenefitHistoryStore.reducer,
    clientOffices: ClientOfficesStore.reducer,
    addOrEditTeam: AddOrEditTeamStore.reducer,
    addOrEditBenefit: AddOrEditBenefitStore.reducer,
    adminAccounts: AdminAccountsStore.reducer,
    adminLogs: AdminLogsStore.reducer,
    changeHotdeskBookingPrices: ChangeHotdeskBookingPricesStore.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export interface AppThunkActionAsync<TAction, TResult> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): Promise<TResult>
}

export interface AppThunkArrayAction<TAction> {
    (dispatch: (action: TAction, key: string) => void, getState: () => ApplicationState): void;
}

export interface AppThunkArrayActionAsync<TAction, TResult> {
    (dispatch: (action: TAction, key: string) => void, getState: () => ApplicationState): Promise<TResult>
}

// create methods with the key as parameter

export function initAllStores(dispatch, getState) {
    LoginStore.actionCreators.init()(dispatch, getState);
    LoginStore.initBoundActions(dispatch, getState);
}