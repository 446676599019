import bind from "bind-decorator";
import React from "react";
import Helmet from 'react-helmet';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from "../../store";
import { String_Format } from "../../utils/utils";
import { RoomBookingsGridStore } from "../../store/RoomBookingsGridStore";
import RoomBookingsTable from "../../components/RoomBookings/RoomBookingsTable";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import RoomBookingsService from "../../services/RoomBookingsService";
import { LocalDateTime } from "@js-joda/core";

interface IProps {
    filters: string,
    initialize: (defaultSelectedItemsPerPageOption: number) => void,
    reload: () => Promise<any>,
    changeCurrentPage: (currentPage: number) => void,
    setFilters: (filters: string) => void,
}

class PendingRoomRequestsPage extends React.PureComponent<any, any>{
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    statusFilterTemplate = `StatusId eq {0}`;
    singleSelectedStatus = "1";

    @bind
    async exportLogs() {
        await RoomBookingsService.getRoomBookingsForExport(String_Format(this.statusFilterTemplate, this.singleSelectedStatus)).then(res => {
            var entities = res.value ? res.value.entities.map(e => Object.entries(e)
            .filter(([key]) => key != "unixStartsAtWithTimezone" && key != "timezone" && key != "workspaceCountryFlag")
            .map(([key, value]) => typeof value === 'string' ? value.replaceAll(',', ' ') : value)
            .join(","))
            .join("\n") : "";

            const csvContent = RoomBookingsGrid.Resources.exportRoomBookingLogsHeader + "\n"
                + entities;

            const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", RoomBookingsGrid.Resources.pendingRoomsRequest + "_" + LocalDateTime.now() + RoomBookingsGrid.Resources.csvExtension);
            document.body.appendChild(link);

            link.click();
        });
    }

    render() {
        return <div className="space-list-page">
            <Helmet title={RoomBookingsGrid.Resources.pendingRoomsRequest} />

            <div className="page-top-container">
                <div className="page-title">{RoomBookingsGrid.Resources.pendingRoomsRequest}</div>

                <div className="page-actions">

                    <div className="btn-big btn-secondary" onClick={this.exportLogs}>
                        <p className="btn-secondary-text">{RoomBookingsGrid.Resources.exportRoomsLogsCsv}</p>
                    </div>

                </div>
            </div>
           
            <RoomBookingsTable
                componentId={componentId}
                hiddenColumns={["statusName", "userCompanyId", "userFirstName", "userLastName", "userId", "userEmail", "userPhone", "userCompanyName", "roomName", "workspaceName", "workspaceCityId", "workspaceCityName", "workspaceCountryId", "workspaceCountryName", "workspaceCountryFlag", "currency", "bookingAmount", "timezone", "startsAt", "endsAt", "expiresAt", "cancelledAt", "rejectedAt", "unixStartsAtWithTimezone"]}
                expandableColumns={["userId"]}
                filters={String_Format(this.statusFilterTemplate, this.singleSelectedStatus)}
                showPendingActionsColumn={true}
            />

        </div>

    }
}

let componentId = "PendingRoomRequestsGridPage";

export default connect(
    (state: ApplicationState) => {
        return {}
    },
    (dispatch) => bindActionCreators({
        ...RoomBookingsGridStore.getActionCreators(componentId),
    }, dispatch)
)(PendingRoomRequestsPage as any);