import * as React from 'react';
import { Routes, Route } from 'react-router';
import { Navigate } from 'react-router-dom';
import { AuthorizedLayoutComponent } from './layouts/Layout';
import { ApplicationPaths } from './enums/Common/ApplicationPaths';
import NotFoundPage from './pages/NotFoundPage';
import UnauthorizedPage from './pages/UnauthorizedPage';
import SpaceGridPage from './pages/Spaces/SpaceGridPage';
import UserDetails from './pages/Users/UserDetailsPage';
import ClientsGridPage from './pages/Clients/ClientsGridPage';
import AddOrEditSpacePage from './pages/Spaces/AddOrEditSpacePage';
import AddOrEditClientPage from './pages/Clients/AddOrEditClientPage';
import ClientsDetailsPage from './pages/Clients/ClientsDetailsPage';
import SpaceDetailsPage from './pages/Spaces/SpaceDetailsPage';
import CountriesAndCitiesPage from './pages/Countries/CountriesAndCitiesPage';
import AddOrEditRoomPage from './pages/Rooms/AddOrEditRoomPage';
import ManageAccountsPage from './pages/ManageAccounts/ManageAccountsPage';
import HotdeskBookingsGridPage from './pages/HotdeskBookings/HotdeskBookingsGridPage';
import RoomBookingsGridPage from './pages/RoomBookings/RoomBookingsGridPage';
import AddOrEditHotdeskBookingPage from './pages/HotdeskBookings/AddOrEditHotdeskBookingPage';
import UsersGridPage from './pages/Users/UsersGridPage';
import AddOrEditRoomBookingPage from './pages/RoomBookings/AddOrEditRoomBookingPage';
import AddOrEditOfficePage from './pages/Spaces/AddOrEditOfficePage';
import PendingRoomRequestsPage from './pages/RoomBookings/PendingRoomRequestsPage';
import AddOrEditTeamPage from './pages/Teams/AddOrEditTeamPage';
import AddOrEditBenefitPage from './pages/Benefits/AddOrEditBenefitPage';
import { AccountsAndLogsPage } from './pages/AccountsAndLogs/AccountsAndLogsPage';
import EditSpacePricePage from './pages/Spaces/EditSpacePricePage';

export default class RouteList extends React.Component<any, any> {

    public render() {
        return (
            <Routes>
                <Route path={ApplicationPaths.Unauthorized} element={<UnauthorizedPage />} />

                <Route path={ApplicationPaths.EmptyPath} element={<Navigate to={ApplicationPaths.Spaces} replace/>} />;
                <Route path={ApplicationPaths.ViewSpaceDetails} element={<AuthorizedLayoutComponent><SpaceDetailsPage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.AddSpace} element={<AuthorizedLayoutComponent><AddOrEditSpacePage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.EditSpace} element={<AuthorizedLayoutComponent><AddOrEditSpacePage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.AddRoom} element={<AuthorizedLayoutComponent><AddOrEditRoomPage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.EditRoom} element={<AuthorizedLayoutComponent><AddOrEditRoomPage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.Spaces} element={<AuthorizedLayoutComponent><SpaceGridPage /></AuthorizedLayoutComponent>} />

                <Route path={ApplicationPaths.ViewUserDetails} element={<AuthorizedLayoutComponent><UserDetails /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.Users} element={<AuthorizedLayoutComponent><UsersGridPage /></AuthorizedLayoutComponent>} />

                <Route path={ApplicationPaths.ViewCustomerDetails} element={<AuthorizedLayoutComponent><ClientsDetailsPage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.AddCustomer} element={<AuthorizedLayoutComponent><AddOrEditClientPage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.EditCustomer} element={<AuthorizedLayoutComponent><AddOrEditClientPage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.AddTeam} element={<AuthorizedLayoutComponent><AddOrEditTeamPage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.EditTeam} element={<AuthorizedLayoutComponent><AddOrEditTeamPage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.AddBenefit} element={<AuthorizedLayoutComponent><AddOrEditBenefitPage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.EditBenefit} element={<AuthorizedLayoutComponent><AddOrEditBenefitPage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.AddOffice} element={<AuthorizedLayoutComponent><AddOrEditOfficePage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.EditOffice} element={<AuthorizedLayoutComponent><AddOrEditOfficePage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.Customers} element={<AuthorizedLayoutComponent><ClientsGridPage /></AuthorizedLayoutComponent>} />
               
                <Route path={ApplicationPaths.ManageAccounts} element={<AuthorizedLayoutComponent><ManageAccountsPage /></AuthorizedLayoutComponent>} />
                
                <Route path={ApplicationPaths.AddHotdeskBooking} element={<AuthorizedLayoutComponent><AddOrEditHotdeskBookingPage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.EditHotdeskBooking} element={<AuthorizedLayoutComponent><AddOrEditHotdeskBookingPage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.DesksLogs} element={<AuthorizedLayoutComponent><HotdeskBookingsGridPage /></AuthorizedLayoutComponent>} />
                
                <Route path={ApplicationPaths.AddRoomBooking} element={<AuthorizedLayoutComponent><AddOrEditRoomBookingPage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.EditRoomBooking} element={<AuthorizedLayoutComponent><AddOrEditRoomBookingPage /></AuthorizedLayoutComponent>} />
                <Route path={ApplicationPaths.RoomsLogs} element={<AuthorizedLayoutComponent><RoomBookingsGridPage /></AuthorizedLayoutComponent>} />

                <Route path={ApplicationPaths.PendingRoomRequests} element={<AuthorizedLayoutComponent><PendingRoomRequestsPage /></AuthorizedLayoutComponent>} />

                <Route path={ApplicationPaths.CountriesAndCities} element={<AuthorizedLayoutComponent><CountriesAndCitiesPage /></AuthorizedLayoutComponent>} />

                <Route path={ApplicationPaths.AccountsAndLogs} element={<AuthorizedLayoutComponent><AccountsAndLogsPage /></AuthorizedLayoutComponent>}/>

                <Route path={ApplicationPaths.EditSpacePrice} element={<AuthorizedLayoutComponent><EditSpacePricePage /></AuthorizedLayoutComponent>} />

                <Route path={ApplicationPaths.NotFound} element={<NotFoundPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>);
    }
}