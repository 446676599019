import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { BenefitHistoryItem } from "../../../models/Benefits/BenefitHistoryItem";
import General from "../../../resources/AdminUI/General";
import { BenefitHistoryStore } from "../../../store/BenefitHistoryStore";
import Loading from "../../Shared/Loading";

interface IProps {
  benefitHistory?: BenefitHistoryItem[];
  loading?: boolean;
  hiddenColumns: string[];
  benefitId: number;

  getBenefitHistory?: (benefitId: number) => void;
}

export class BenefitHistoryTable extends React.Component<IProps, any> {
  constructor(props) {
    super(props);

    this.props.getBenefitHistory(this.props.benefitId);
  }

  tableClass: string = "table";

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else if (this.props.benefitHistory.length == 0) {
      return <div>{General.Resources.noElements}</div>;
    } else {
      return (
        <div className="table-wrapper">
          <table className={this.tableClass}>
            <thead>
              <tr>
                {this.props.benefitHistory[0].TrueProperties.filter(
                  (p) => this.props.hiddenColumns.indexOf(p) < 0
                ).map((p) => (
                  <th key={p}>
                    {this.props.benefitHistory[0].GetDisplayNameForProperty(p)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.props.benefitHistory.map((benefit) => {
                return (
                  <tr key={benefit.sourceCycleIndex}>
                    {benefit.TrueProperties.filter(
                      (p) => this.props.hiddenColumns.indexOf(p) < 0
                    ).map((p) => (
                      <td key={p}>{benefit.GetDisplayValueForProperty(p)}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
  }
}

export default connect(
  (state: ApplicationState, ownProps: IProps) => {
    return {
      loading: state.benefitHistory.loading,
      benefitHistory: state.benefitHistory.benefitHistory,
      ...ownProps,
    };
  },
  {
    getBenefitHistory: BenefitHistoryStore.actionCreators.getBenefitHistory,
  }
)(BenefitHistoryTable as any);
