import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from "../../store";
import Helmet from "react-helmet";
import bind from "bind-decorator";
import UsersTable from "../../components/Users/Tables/UsersTable";
import { UsersGridStore } from "../../store/UsersGridStore";
import UsersGrid from "../../resources/AdminUI/Users/UsersGrid";
import {
  String_Format,
  firstLetterToLowerCase,
  getPromiseFromAction,
  nameof,
} from "../../utils/utils";
import RegularExpressions from "../../resources/Common/RegularExpressions";
import { UserStates } from "../../enums/Users/UserStates";
import { UserStateFilterOptions } from "../../enums/Users/UserStateFilterOptions";
import UserStateTypes from "../../resources/AdminUI/Users/UserStateTypes";
import UsersService from "../../services/UsersService";
import { LocalDateTime } from "@js-joda/core";
import { Select } from "antd";
import { withRouter } from "../../components/Shared/withRouter";

class UsersGridPage extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
  }

  searchByUserNameTemplate = `contains(concat(concat(tolower(firstName), ' '), tolower(lastName)), tolower('{0}')) or contains(tolower(Email), tolower('{0}'))`;
  searchByIdTemplate = ` or id eq {0}`;
  searchByCompanyName = ` or contains(companyName, '{0}')`;
  searchByPhoneNumber = ` or contains(phone, '{0}')`;

  deletedStateFilterOptionTemplate = "status eq {0}";
  deactivatedStateFilterOptionTemplate = "status eq {0}";
  notCompletedStateFilterOptionTemplate =
    "status eq {0} and onBoardingComplete eq false";
  activeAndCompletedStateFilterOptionTemplate =
    "status eq {0} and onBoardingComplete eq true";

  hiddenColumnsForUsersTable = [
    "teamName",
    "createdAt",
    "deactivated",
    "id",
    "lastName",
    "phone",
    "status",
    "onBoardingComplete",
    "companyId",
    "isSmsPreferredChannel",
    "isEmailPreferredChannel",
    "totalCheckins",
    "currentMonthCheckins",
    "isBookingManager",
    "marketingPreferences",
    "teamId",
    "changeTeamAction",
  ];

  @bind
  rebuildOdataAndGetData() {
    let filters = [];

    if (this.props.searchedTerm) {
      let searchFilter = String_Format(
        this.searchByUserNameTemplate,
        this.props.searchedTerm
      );

      let pattern = RegularExpressions.Resources.onlyNumbers;
      if (RegExp(pattern).test(this.props.searchedTerm)) {
        searchFilter = searchFilter
          .concat(
            String_Format(this.searchByIdTemplate, this.props.searchedTerm)
          )
          .concat(
            String_Format(this.searchByPhoneNumber, this.props.searchedTerm)
          );
      }

      searchFilter = searchFilter.concat(
        String_Format(this.searchByCompanyName, this.props.searchedTerm)
      );

      filters.push(`(${searchFilter})`);
    }

    if (
      this.props.selectedStateId != "0" &&
      this.props.selectedStateId !=
        UserStateFilterOptions.AllUserStates.toString()
    ) {
      switch (this.props.selectedStateId) {
        case UserStateFilterOptions.Deleted.toString():
          filters.push(
            String_Format(
              this.deletedStateFilterOptionTemplate,
              UserStates.Deleted
            )
          );
          break;
        case UserStateFilterOptions.Deactivated.toString():
          filters.push(
            String_Format(
              this.deactivatedStateFilterOptionTemplate,
              UserStates.Deactivated
            )
          );
          break;
        case UserStateFilterOptions.NotCompleted.toString():
          filters.push(
            String_Format(
              this.notCompletedStateFilterOptionTemplate,
              UserStates.Active
            )
          );
          break;
        case UserStateFilterOptions.ActiveAndAccountCompleted.toString():
          filters.push(
            String_Format(
              this.activeAndCompletedStateFilterOptionTemplate,
              UserStates.Active
            )
          );
          break;
      }
    }

    let oDataFilter = filters.join(" and ");

    this.props.setFilter(oDataFilter);
    this.props.changeCurrentPage(1);
    getPromiseFromAction(this.props.reload()).then(() =>
      this.props.changeCurrentPage(1)
    );
  }

  @bind
  handleOnSearchFilterChange(e) {
    if (this.props.searchedTerm != e.currentTarget.value) {
      getPromiseFromAction(
        this.props.setSearchedTerm(e.currentTarget.value.toString())
      ).then(() => {
        this.rebuildOdataAndGetData();
      });
    }
  }

  @bind
  getUserStates() {
    var options = [];

    let keys = Object.keys(UserStateFilterOptions).filter(
      (k) => typeof UserStateFilterOptions[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = UserStateFilterOptions[key as any];
      options.push({
        value: value.toString(),
        label: UserStateTypes.Resources[firstLetterToLowerCase(key)],
      });
    });

    return options;
  }

  @bind
  handleOnStateFilterChange(value: string, option: any) {
    getPromiseFromAction(this.props.setSelectedStateId(value)).then(() => {
      this.rebuildOdataAndGetData();
    });
  }

  @bind
  async exportUsers() {
    await UsersService.getUsersForExport(this.props.filters).then((res) => {
      let entities = res.value
        ? res.value.entities
            .map((e) =>
              Object.entries(e)
                .filter(([key]) => key != "marketingPreferences")
                .map(([key, value]) =>
                  typeof value === "string" ? value.replaceAll(",", " ") : value
                )
                .join(",")
            )
            .join("\n")
        : "";
      const csvContent =
        "data:text/csv;charset=utf-8," +
        UsersGrid.Resources.exportUsersHeader +
        "\n" +
        entities;

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        UsersGrid.Resources.users +
          "_" +
          LocalDateTime.now() +
          UsersGrid.Resources.csvExtension
      );
      document.body.appendChild(link);

      link.click();
    });
  }
  render() {
    return (
      <div>
        <Helmet title={UsersGrid.Resources.users}></Helmet>

        <div className="page-top-container">
          <div className="page-title">{UsersGrid.Resources.users}</div>
          <div className="page-actions">
            <div className="btn-big btn-secondary" onClick={this.exportUsers}>
              <p className="btn-secondary-text">
                {UsersGrid.Resources.exportUsersButtonText}
              </p>
            </div>
          </div>
        </div>

        <div className="filter-bar">
          <div className="filter-search">
            <div className="input-group">
              <span className="icon material-symbols-outlined">search</span>
              <input
                type="text"
                className="form-control"
                placeholder={UsersGrid.Resources.searchFilterPlaceholder}
                onChange={this.handleOnSearchFilterChange}
                name={nameof((e) => e.searchFilter)}
                value={this.props.searchedTerm}
              />
            </div>
          </div>

          <div className="filter-item">
            <div className="input-group">
              <Select
                options={this.getUserStates()}
                onChange={this.handleOnStateFilterChange}
                value={this.props.selectedStateId}
              ></Select>
            </div>
          </div>
        </div>

        <UsersTable
          componentId={componentId}
          hiddenColumns={this.hiddenColumnsForUsersTable}
        />
      </div>
    );
  }
}

const componentId = "UsersGridPage";

export default withRouter(
  connect(
    (state: ApplicationState) => {
      const componentState = state.users[componentId];
      return {
        filters: componentState?.filters || "",
        searchedTerm: componentState?.searchedTerm || "",
        selectedStateId:
          componentState?.selectedStateId ||
          UserStateFilterOptions.AllUserStates.toString(),
      };
    },
    (dispatch) =>
      bindActionCreators(
        {
          ...UsersGridStore.getActionCreators(componentId),
        },
        dispatch
      )
  )(UsersGridPage)
);
