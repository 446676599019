import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { TabStore } from "../../../store/TabStore";
import { TabsHeader } from "../../Tabs/TabsHeader";
import General from "../../../resources/AdminUI/General";
import { DetailsTypes } from "../../../enums/Common/DetailsTypes";
import { SpacePricing } from "./SpacePricing";
import { ITabItem, ITabTitleItem } from "../../../utils/reactUtils";
import { WIFISetupAndAccess } from "./WIFISetupAndAccess";
import { AppSettings } from "../../../utils/settings";
import { ContactInfoTab } from "../../Tabs/ContactInfoTab";
import { DescriptionTab } from "../../Tabs/DescriptionTab";
import { AmenitiesTab } from "../../Tabs/AmenitiesTab";
import { ScheduleTab } from "../../Tabs/ScheduleTab";
import Workspaces from "../../../resources/AdminUI/Spaces/Workspaces";
import NewImageGalleryViewer from "../../Shared/NewImageGalleryViewer";
import { bindActionCreators } from "redux";

class SpaceDetailsTabsComponent extends React.PureComponent<any, any>{
    constructor(props) {
        super(props);
        var tabs = [
            {
                tabId: DetailsTypes.Photographs.toString(),
                title: Workspaces.Resources.photographs,
                class: '',
                content: () => <NewImageGalleryViewer itemUrls={this.props.images && this.props.images.length > 0 ? this.props.images.map(e => { return AppSettings.CommonSettings.ImageBaseUrl + e }):[]}></NewImageGalleryViewer>
            } as ITabItem,
            {
                tabId: DetailsTypes.Pricing.toString(),
                title: Workspaces.Resources.pricing,
                class: '',
                content: () =>
                    <SpacePricing vat={this.props.vat} fullDayPrice={this.props.fullDayPrice}
                        halfDayPrice={this.props.halfDayPrice}
                        unredeemedPrice={this.props.unredeemedPrice}
                        currencyLabel={this.props.currencyLabel} />
            } as ITabItem,
            {
                tabId: DetailsTypes.Description.toString(),
                title: Workspaces.Resources.description,
                class: '',
                content: () => <DescriptionTab
                    descriptionEn={this.props.descriptionEn}
                    descriptionEs={this.props.descriptionEs}
                    descriptionPt={this.props.descriptionPt}
                />
            } as ITabItem,
            {
                tabId: DetailsTypes.Amenities.toString(),
                title: Workspaces.Resources.amenities,
                class: '',
                content: () => <AmenitiesTab amenities={this.props.amenities} />
            } as ITabItem,
            {
                tabId: DetailsTypes.Schedule.toString(),
                title: Workspaces.Resources.schedule,
                class: '',
                content: () => <ScheduleTab schedule={this.props.schedule} />
            } as ITabItem,
            {
                tabId: DetailsTypes.ContactInfo.toString(),
                title: Workspaces.Resources.contactInfo,
                class: '',
                content: () => <ContactInfoTab email={this.props.email} phone={this.props.phone} />
            } as ITabItem,
            {
                tabId: DetailsTypes.BusinessContactInfo.toString(),
                title: Workspaces.Resources.businessContactInfo,
                class: '',
                content: () => <ContactInfoTab email={this.props.businessEmail} phone={this.props.businessPhone} />
            } as ITabItem,
            {
                tabId: DetailsTypes.WIFISetupAndAccess.toString(),
                title: Workspaces.Resources.wifiSetupAndAccess,
                class: '',
                content: () =>
                    <WIFISetupAndAccess
                        wifiName={this.props.wifiName}
                        wifiPassword={this.props.wifiPassword}
                        accessInstructionsEn={this.props.accessInstructionsEn} 
                        accessInstructionsEs={this.props.accessInstructionsEs} 
                        accessInstructionsPt={this.props.accessInstructionsPt} 
                        />
            } as ITabItem
        ];

        this.props.initializeTabPage(tabs, DetailsTypes.Photographs.toString(), null);


    }

    render() {
        {
            if (this.props.tabState == undefined) {
                return <div>
                    <h2 className="text-center">{General.Resources.loading}</h2>
                </div>
            }
        }
        var activeTab = this.props.tabState.tabs.filter(t => t.tabId == this.props.tabState.activeTabId)[0];
        return <div>
            <TabsHeader onTabClick={this.props.changeActiveTab}
                activeTabId={activeTab.tabId}
                className={"page-tabs"}
                tabs={this.props.tabState.tabs.map(t => { return { title: t.title, tabId: t.tabId, class: t.class } as ITabTitleItem })} />
            <div className="tab-content spaces-info-tabs">
                {activeTab.content()}
            </div>
        </div>
    }
}


export default connect(
    (state: ApplicationState, ownProps: any) => {
        return { tabState: state.tab[ownProps.componentId] }
    },
    (dispatch, ownProps: any) => bindActionCreators({
        ...TabStore.getActionCreators(ownProps.componentId)
    }, dispatch)
)(SpaceDetailsTabsComponent);
