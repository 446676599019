import React from "react";
import Helmet from "react-helmet";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { NavigateFunction } from "react-router-dom";
import {
  String_Format,
  firstLetterToLowerCase,
  getPromiseFromAction,
} from "../../utils/utils";
import {
  ApplicationPathBuilder,
  ApplicationPaths,
} from "../../enums/Common/ApplicationPaths";
import SpaceDetailsTabsComponent from "../../components/Spaces/SpaceDetailsTabs/SpaceDetailsTabsComponent";
import General from "../../resources/AdminUI/General";
import { SpaceDetailsStore } from "../../store/SpaceDetailsStore";
import Workspaces from "../../resources/AdminUI/Spaces/Workspaces";
import { RoomCardsSlider } from "../../components/Spaces/SpaceDetailsTabs/RoomCardsSlider";
import Rooms from "../../resources/AdminUI/Rooms/Rooms";
import bind from "bind-decorator";
import SpaceDetailsBookingsTablesTabComponent from "../../components/Spaces/SpaceDetailsTabs/SpaceDetailsBookingsTablesTabComponent";
import { SpaceStatuses } from "../../enums/Spaces/SpaceStatuses";
import SpaceStatusesList from "../../resources/AdminUI/Spaces/SpaceStatusesList";
import { AppSettings } from "../../utils/settings";
import IDropdownButtonItem from "../../models/Common/IDropdownButtonItem";
import DropdownButton from "../../components/Shared/DropdownButton";
import { SpaceCategories } from "../../enums/Spaces/SpaceCategories";
import SpaceCategoryList from "../../resources/AdminUI/Spaces/SpaceCategoryList";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ErrorMessages from "../../resources/Common/ErrorMessages";
import { AddOrEditRoomStore } from "../../store/AddOrEditRoomStore";
import { IWorkspaceSchedule } from "../../models/Spaces/Interfaces/IWorkspaceSchedule";
import { withRouter } from "../../components/Shared/withRouter";
import { formatDate } from "../../utils/date";
import Loading from "../../components/Shared/Loading";
const { confirm } = Modal;

interface IProps {
  navigate: NavigateFunction;
  params: URLSearchParams;

  loading?: boolean;

  getData?: (id: number) => void;
  changeStatus?: (workspaceId: number, statusId: number) => any;
  setWorkspaceSchedule?: (workspaceSchedule: IWorkspaceSchedule[]) => void;
}

interface IState {
  id: number;
}

class SpaceDetailsPage extends React.PureComponent<any, IState> {
  constructor(props) {
    super(props);

    if (props.params.has("id")) {
      const id = parseInt(props.params.get("id"));
      this.state = { id: id };
      this.props.getData(id);
    } else {
      this.props.navigate(ApplicationPaths.NotFound);
    }
  }

  tableFilterTemplate = `WorkspaceId eq {0}`;

  @bind
  showDraftModal() {
    confirm({
      title: Workspaces.Resources.areYouSure,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{Workspaces.Resources.draftConfirmationMessage}</p>
        </div>
      ),
      okText: Workspaces.Resources.changeStatusToDraft,
      okType: "primary",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        this.changeSpaceStatus(SpaceStatuses.Draft);
      },
      onCancel() {},
    });
  }

  @bind
  showUnavailableModal() {
    confirm({
      title: Workspaces.Resources.areYouSure,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{Workspaces.Resources.unavailableConfirmationMessage}</p>
        </div>
      ),
      okText: Workspaces.Resources.changeStatusToUnavailable,
      okType: "primary",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        this.changeSpaceStatus(SpaceStatuses.Unavailable);
      },
      onCancel() {},
    });
  }

  @bind
  showActiveModal() {
    confirm({
      title: Workspaces.Resources.areYouSure,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{Workspaces.Resources.activeConfirmationMessage}</p>
        </div>
      ),
      okText: Workspaces.Resources.activateSpace,
      okType: "primary",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        this.changeSpaceStatus(SpaceStatuses.Available);
      },
      onCancel() {},
    });
  }

  @bind
  showDeactivatedModal() {
    confirm({
      title: Workspaces.Resources.areYouSure,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{Workspaces.Resources.deactivatedConfirmationMessage}</p>
        </div>
      ),
      okText: Workspaces.Resources.deactivateSpace,
      okType: "danger",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        this.changeSpaceStatus(SpaceStatuses.Deactivated);
      },
      onCancel() {},
    });
  }

  @bind
  changeSpaceStatus(statusId) {
    getPromiseFromAction(
      this.props.changeStatus(this.props.details.id, statusId)
    ).then(() => {
      if (this.props.hasServerSideErrors) {
        Modal.error({
          title: ErrorMessages.Resources.generalErrorMessage,
        });
      }
    });
  }

  @bind
  getItemsForChangeStatusDropdownButton() {
    switch (this.props.details.statusId) {
      case SpaceStatuses.Available:
        return [
          {
            icon: "stylus_note",
            text: SpaceStatusesList.Resources.draft,
            action: this.showDraftModal,
          },
          {
            icon: "event_busy",
            text: SpaceStatusesList.Resources.unavailable,
            action: this.showUnavailableModal,
          },
          {
            icon: "toggle_off",
            text: SpaceStatusesList.Resources.deactivated,
            action: this.showDeactivatedModal,
          },
        ] as IDropdownButtonItem[];
      case SpaceStatuses.Draft:
        return [
          {
            icon: "toggle_on",
            text: SpaceStatusesList.Resources.available,
            action: this.showActiveModal,
          },
          {
            icon: "event_busy",
            text: SpaceStatusesList.Resources.unavailable,
            action: this.showUnavailableModal,
          },
          {
            icon: "toggle_off",
            text: SpaceStatusesList.Resources.deactivated,
            action: this.showDeactivatedModal,
          },
        ] as IDropdownButtonItem[];
      case SpaceStatuses.Unavailable:
        return [
          {
            icon: "stylus_note",
            text: SpaceStatusesList.Resources.draft,
            action: this.showDraftModal,
          },
          {
            icon: "toggle_on",
            text: SpaceStatusesList.Resources.available,
            action: this.showActiveModal,
          },
          {
            icon: "toggle_off",
            text: SpaceStatusesList.Resources.deactivated,
            action: this.showDeactivatedModal,
          },
        ] as IDropdownButtonItem[];
      case SpaceStatuses.Deactivated:
        return [
          {
            icon: "stylus_note",
            text: SpaceStatusesList.Resources.draft,
            action: this.showDraftModal,
          },
          {
            icon: "toggle_on",
            text: SpaceStatusesList.Resources.available,
            action: this.showActiveModal,
          },
          {
            icon: "event_busy",
            text: SpaceStatusesList.Resources.unavailable,
            action: this.showUnavailableModal,
          },
        ] as IDropdownButtonItem[];
      default:
        return [] as IDropdownButtonItem[];
    }
  }

  @bind
  goToAddRoom() {
    this.props.navigate(
      ApplicationPathBuilder.AddRoom(this.state.id.toString())
    );
  }

  @bind
  getCategoryName() {
    var category = SpaceCategories[this.props.details.categoryId];
    var categoryName =
      SpaceCategoryList.Resources[firstLetterToLowerCase(category)];

    return categoryName;
  }

  @bind
  goToEditSpace() {
    if (
      this.props.details.categoryId == SpaceCategories.CompanyOffice.toString()
    ) {
      this.props.navigate(
        ApplicationPathBuilder.EditOffice(this.props.details.id)
      );
    } else {
      this.props.navigate(
        ApplicationPathBuilder.EditSpace(this.props.details.id)
      );
    }
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    return (
      <div>
        <Helmet title={Workspaces.Resources.spaceDetails} />

        <div className="details-page-top">
          <div className="page-navigation">
            <span
              className="material-symbols-outlined"
              onClick={() => this.props.navigate(-1)}
            >
              arrow_back
            </span>
            <div className="page-title">
              {Workspaces.Resources.spaceDetails}
            </div>
          </div>

          <div className="page-actions">
            <DropdownButton
              text={Workspaces.Resources.changeStatus}
              items={this.getItemsForChangeStatusDropdownButton()}
              icon="arrow_drop_down"
              className="btn-big btn-secondary"
            />

            <button
              className="btn-big btn-primary"
              onClick={this.goToEditSpace}
            >
              <span className="material-symbols-outlined">edit</span>
              <p className="btn-primary-text">
                {Workspaces.Resources.editSpaceInfo}
              </p>
            </button>
          </div>
        </div>

        <div className="two-columns-container">
          <section className="container">
            <div className="item-general-info">
              <div className="type">{this.getCategoryName()}</div>
              <h2 className="item-name">{this.props.details.name}</h2>
              <div className="db_info">
                <p>
                  <span
                    className={`status ${firstLetterToLowerCase(
                      SpaceStatuses[this.props.details.statusId]
                    )}`}
                  >
                    {
                      SpaceStatusesList.Resources[
                        firstLetterToLowerCase(
                          SpaceStatuses[this.props.details.statusId]
                        )
                      ]
                    }{" "}
                  </span>
                </p>
                <div className="id_created_on">
                  {" "}
                  | {String_Format(
                    Workspaces.Resources.idLabel,
                    this.state.id
                  )}{" "}
                  |{" "}
                  {String_Format(
                    Workspaces.Resources.createdOn,
                    formatDate(this.props.details.createdAt)
                  )}
                </div>
              </div>

              <div className="header">{Workspaces.Resources.spaceAddress}</div>
              <div className="country">
                {this.props.details.countryFlag}{" "}
                {this.props.details.countryName}, {this.props.details.cityName}
              </div>
              <div className="address">{this.props.details.address}</div>
              <a
                className="btn-text"
                target="_blank"
                href={String_Format(
                  AppSettings.CommonSettings.GoogleMapsSearchUrl,
                  this.props.details.lat,
                  this.props.details.lng
                )}
              >
                {Workspaces.Resources.seeSpaceInGoogleMaps}
              </a>
              <div className="header">{Workspaces.Resources.desksBooking}</div>
              {this.props.details.maxSeats == 0 ? (
                <div>{Workspaces.Resources.spaceDoesNotOfferHotDesks}</div>
              ) : !this.props.details.maxSeats ? (
                <div>
                  {Workspaces.Resources.spaceDoesNotHaveLimitForHotdesks}
                </div>
              ) : (
                <div>
                  {this.props.details.maxSeats
                    ? String_Format(
                        Workspaces.Resources
                          .spacesRequiresBookingLimitDesksPerDay,
                        this.props.details.maxSeats
                      )
                    : null}
                </div>
              )}
            </div>
          </section>

          <section className="container">
            <div className="item-more-info-container">
              <SpaceDetailsTabsComponent
                componentId={`space-details-${this.state.id}`}
                images={this.props.details.images}
                vat={this.props.details.vat}
                fullDayPrice={this.props.details.fullDayPrice}
                halfDayPrice={this.props.details.halfDayPrice}
                unredeemedPrice={this.props.details.unredeemedPrice}
                currencyLabel={this.props.details.currency}
                descriptionEn={this.props.details.descriptionEn}
                descriptionEs={this.props.details.descriptionEs}
                descriptionPt={this.props.details.descriptionPt}
                amenities={this.props.details.amenities}
                schedule={this.props.details.schedule}
                email={this.props.details.email}
                phone={this.props.details.phone}
                businessEmail={this.props.details.businessEmail}
                businessPhone={this.props.details.businessPhone}
                wifiName={this.props.details.wifiName}
                wifiPassword={this.props.details.wifiPassword}
                accessInstructionsEn={this.props.details.accessInstructionsEn}
                accessInstructionsEs={this.props.details.accessInstructionsEs}
                accessInstructionsPt={this.props.details.accessInstructionsPt}
              ></SpaceDetailsTabsComponent>
            </div>
          </section>
        </div>

        <div className="container">
          <div className="rooms-sections">
            <div className="section-top">
              <div className="section-title">
                {Workspaces.Resources.typesOfRooms}
              </div>
              <div className="page-actions">
                <button
                  className="btn-big btn-primary"
                  onClick={this.goToAddRoom}
                >
                  <span className="material-symbols-outlined">add_circle</span>
                  <p className="btn-primary-text">{Rooms.Resources.addRoom}</p>
                </button>
              </div>
            </div>
          </div>

          {!this.props.details.rooms || this.props.details.rooms.length == 0 ? (
            <div>
              {Workspaces.Resources.spaceHasNoMeetingRoomsOrPrivateOfficesYet}
            </div>
          ) : (
            <RoomCardsSlider
              slides={this.props.details.rooms}
              currency={this.props.details.currency}
            />
          )}
        </div>

        <div className="container">
          <SpaceDetailsBookingsTablesTabComponent
            componentId={`space-bookings-${this.state.id}`}
            spaceId={this.state.id}
            hotdeskBookingsTableFilters={String_Format(
              this.tableFilterTemplate,
              this.state.id
            )}
            hotdeskBookingsTableHiddenColumns={[
              "userEmail",
              "userPhone",
              "statusName",
              "userFirstName",
              "userLastName",
              "userCompanyName",
              "workspaceName",
              "workspaceCategoryId",
              "workspaceCityId",
              "workspaceCityName",
              "workspaceCountryId",
              "workspaceCountryName",
              "workspaceCountryFlag",
              "timeSpent",
              "isFullDay",
              "createdAt",
              "checkinAt",
              "checkoutAt",
              "expiresAt",
              "cancelledAt",
              "bookingAt",
              "timezone",
              "isWalkin",
              "isAutomatedCheckout",
              "unixBookingAtWithTimezone",
            ]}
            hotdeskBookingsTableExpandableColumns={["createdAt"]}
            showActionsColumn={true}
            roomBookingsTableFilters={String_Format(
              this.tableFilterTemplate,
              this.state.id
            )}
            roomBookingsTableHiddenColumns={[
              "statusName",
              "userLastName",
              "userId",
              "userEmail",
              "userPhone",
              "userCompanyName",
              "roomName",
              "workspaceName",
              "workspaceCityId",
              "workspaceCityName",
              "workspaceCountryId",
              "workspaceCountryName",
              "workspaceCountryFlag",
              "currency",
              "bookingAmount",
              "timezone",
              "startsAt",
              "endsAt",
              "expiresAt",
              "cancelledAt",
              "rejectedAt",
              "unixStartsAtWithTimezone",
            ]}
            roomBookingsTableExpandableColumns={["userId"]}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        details: state.spaceDetails.details,
        loading: state.spaceDetails.loading,
      };
    },
    {
      getData: SpaceDetailsStore.actionCreators.getData,
      changeStatus: SpaceDetailsStore.actionCreators.changeStatus,
      setWorkspaceSchedule:
        AddOrEditRoomStore.actionCreators.setWorkspaceSchedule,
    }
  )(SpaceDetailsPage)
);
