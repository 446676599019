import React from "react";
import Helmet from "react-helmet";
import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import AddOrEditClient from "../../resources/AdminUI/Clients/AddOrEditClient";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { LookupStore } from "../../store/LookupStore";
import { NavigateFunction } from "react-router-dom";
import { AddOrEditClientStore } from "../../store/AddOrEditClientStore";
import AddOrEditClientForm from "../../components/Clients/Forms/AddOrEditClientForm";
import General from "../../resources/AdminUI/General";
import bind from "bind-decorator";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import { withRouter } from "../../components/Shared/withRouter";
import Loading from "../../components/Shared/Loading";

interface IProps {
  navigate?: NavigateFunction;
  params: URLSearchParams;

  loading: boolean;
  countries: IAntdSelectListItem[];
  currencies: IAntdSelectListItem[];

  getCountries: (allOpt?: boolean) => void;
  getCurrencies: () => void;
  initializeCreatePage: () => void;
  initializeEditPage: (id: number) => void;
}

interface IState {
  isEditingMode: boolean;
  clientId: number;
}

class AddOrEditClientPage extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    if (window.location.pathname == ApplicationPaths.AddCustomer) {
      this.state = {
        isEditingMode: false,
        clientId: null,
      };
      this.props.initializeCreatePage();
    } else if (window.location.pathname == ApplicationPaths.EditCustomer) {
      var clientId = parseInt(props.params.get("id"));
      this.state = {
        isEditingMode: true,
        clientId: clientId,
      };
      this.props.initializeEditPage(clientId);
    }

    this.props.getCountries(false);
    this.props.getCurrencies();
  }

  @bind
  goBack() {
    this.props.navigate(-1);
  }

  render() {
    if (this.props.loading || !this.props.countries || !this.props.currencies) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else {
      return (
        <div>
          {this.state.isEditingMode ? (
            <Helmet
              title={AddOrEditClient.Resources.editCustomerHeader}
            ></Helmet>
          ) : (
            <Helmet title={AddOrEditClient.Resources.addCustomerText}></Helmet>
          )}

          <div className="details-page-top">
            <div className="page-navigation">
              <span className="material-symbols-outlined" onClick={this.goBack}>
                arrow_back
              </span>
              <div className="page-title">
                {this.state.isEditingMode ? (
                  <h1>{AddOrEditClient.Resources.editCustomerHeader}</h1>
                ) : (
                  <h1>{AddOrEditClient.Resources.addCustomerText}</h1>
                )}
              </div>
            </div>
          </div>

          <AddOrEditClientForm editingMode={this.state.isEditingMode} />
        </div>
      );
    }
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        countries: state.lookup.allCountries,
        currencies: state.lookup.currencies,
        loading: state.addOrEditClient.loading,
      };
    },
    {
      getCountries: LookupStore.actionCreators.getCountries,
      getCurrencies: LookupStore.actionCreators.getCurrencies,
      initializeCreatePage:
        AddOrEditClientStore.actionCreators.initializeCreatePage,
      initializeEditPage:
        AddOrEditClientStore.actionCreators.initializeEditPage,
    }
  )(AddOrEditClientPage as any)
);
