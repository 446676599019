import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import update from "immutability-helper";
import SpacesService from "../services/SpacesService";
import { getServerSideErrors } from "../utils/utils";
import { IWorkspaceSchedule } from "../models/Spaces/Interfaces/IWorkspaceSchedule";
import LookupService from "../services/LookupService";
import { ISelectListItemForAmenity } from "../models/Common/ISelectListItemForAmenity";

export namespace AddOrEditSpaceStore {
  export interface IState {
    loading: boolean;

    id: number;
    name?: string;
    categoryId?: string;
    address?: string;
    countryId?: string;
    cityId?: string;
    lat?: number;
    lng?: number;
    currency?: string;
    spaceOffersHotDesks?: boolean;
    requireBooking?: boolean;
    maxSeats?: number;
    descriptionEn?: string;
    descriptionEs?: string;
    descriptionPt?: string;
    email?: string;
    phone?: string;
    localeId?: string;
    wifiName?: string;
    wifiPassword?: string;
    accessInstructionsEn?: string;
    accessInstructionsEs?: string;
    accessInstructionsPt?: string;
    groupId?: string;
    groupName?: string;
    groupEmailAddress?: string;
    groupPassword?: string;
    vat?: number;
    fullDayPrice?: number;
    halfDayPrice?: number;
    unredeemedPrice?: number;
    meetingRoomCommission?: number;
    privateOfficeCommission?: number;
    schedule?: IWorkspaceSchedule[];
    images: string[];
    amenities: ISelectListItemForAmenity[];
    businessEmail: string;
    businessPhone: string;
    notes: string;
    automaticEmailReceivers: string[];
    automaticMessageByWhatsappReceivers: string[];
    metaTitle: string;
    metaDescription: string;
    mealIncluded: boolean;

    hasServerSideErrors: boolean;
    errors: string;
  }

  export enum Actions {
    ToggleLoading = "ADD_OR_EDIT_SPACE_TOGGLE_LOADING",
    Initialize = "ADD_OR_EDIT_SPACE_INITIALIZE",
    SetSpaceDetails = "ADD_OR_EDIT_SPACE_SET_SPACE_DETAILS",
    SetSpaceImages = "ADD_OR_EDIT_SPACE_SET_SPACE_IMAGES",
    SetSpaceSchedule = "ADD_OR_EDIT_SPACE_SET_SPACE_SCHEDULE",
    SetSpaceAmenities = "ADD_OR_EDIT_SPACE_SET_SPACE_AMENITIES",
    SaveSpaceDetails = "ADD_OR_EDIT_SPACE_SAVE_SPACE_DETAILS",
    SetErrors = "ADD_OR_EDIT_SPACE_SET_ERRORS",
  }

  interface IInitialize {
    type: Actions.Initialize;
  }

  interface IToggleLoading {
    type: Actions.ToggleLoading;
    loading: boolean;
  }

  interface ISetSpaceDetails {
    type: Actions.SetSpaceDetails;
    id: number;
    spaceDetails: any;
  }

  interface ISetSpaceImages {
    type: Actions.SetSpaceImages;
    images: string[];
  }

  interface ISetSpaceSchedule {
    type: Actions.SetSpaceSchedule;
    schedule: IWorkspaceSchedule[];
  }

  interface ISetSpaceAmenities {
    type: Actions.SetSpaceAmenities;
    amenities: ISelectListItemForAmenity[];
  }

  interface ISetErrors {
    type: Actions.SetErrors;
    hasServerSideErrors: boolean;
    errors: string;
  }

  type KnownAction =
    | IToggleLoading
    | IInitialize
    | ISetSpaceDetails
    | ISetSpaceImages
    | ISetSpaceSchedule
    | ISetErrors
    | ISetSpaceAmenities;

  export const actionCreators = {
    initializeCreatePage:
      (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: Actions.Initialize });
      },

    initializeEditPage:
      (id: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ type: Actions.ToggleLoading, loading: true });

        var details = await SpacesService.getSpaceDetailsForEdit(id).then(
          (response) => response.value
        );

        dispatch({
          type: Actions.SetSpaceDetails,
          id: id,
          spaceDetails: details,
        });
        dispatch({ type: Actions.ToggleLoading, loading: false });
      },

    setSpaceImages:
      (images: string[]): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          type: Actions.SetSpaceImages,
          images: images,
        });
      },

    setSpaceSchedule:
      (schedule: IWorkspaceSchedule[]): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          type: Actions.SetSpaceSchedule,
          schedule: schedule,
        });
      },

    setSpaceAmenities:
      (amenities: ISelectListItemForAmenity[]): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          type: Actions.SetSpaceAmenities,
          amenities: amenities,
        });
      },

    getSpaceAmenities:
      (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        var amenities = await LookupService.getAllAmenities().then(
          (res) => res.value
        );
        dispatch({
          type: Actions.SetSpaceAmenities,
          amenities: amenities,
        });
      },

    saveDetails:
      (
        appendToAnExistingGroup: boolean,
        id?: number,
        name?: string,
        categoryId?: string,
        countryId?: string,
        cityId?: string,
        address?: string,
        lat?: number,
        lng?: number,
        currency?: string,
        requireBooking?: boolean,
        maxSeats?: number,
        descriptionEn?: string,
        descriptionEs?: string,
        descriptionPt?: string,
        email?: string,
        phone?: string,
        localeId?: string,
        wifiName?: string,
        wifiPassword?: string,
        accessInstructionsEn?: string,
        accessInstructionsEs?: string,
        accessInstructionsPt?: string,
        groupId?: string,
        groupName?: string,
        groupEmailAddress?: string,
        groupPassword?: string,
        vat?: number,
        fullDayPrice?: number,
        halfDayPrice?: number,
        unredeemedPrice?: number,
        meetingRoomCommission?: number,
        privateOfficeCommission?: number,
        businessEmail?: string,
        businessPhone?: string,
        notes?: string,
        automaticEmailReceivers?: string[],
        automaticMessageByWhatsappReceivers?: string[],
        metaTitle?: string,
        metaDescription?: string,
        mealIncluded?: boolean
      ): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        var state = getState().addOrEditSpace;
        var schedule = state.schedule?.map((e, i) => {
          e = e.isClosed == true ? null : e;
          return e;
        });

        var details = {
          name: name,
          statusTypeId: categoryId,
          categoryId: categoryId,
          address: address,
          cityId: cityId,
          countryId: countryId,
          lat: lat,
          lng: lng,
          currency: currency,
          requireBooking: requireBooking,
          maxSeats: maxSeats,
          descriptionEn: !descriptionEn ? null : descriptionEn,
          descriptionEs: !descriptionEs ? null : descriptionEs,
          descriptionPt: !descriptionPt ? null : descriptionPt,
          email: !email ? null : email,
          phone: !phone ? null : phone,
          localeId: localeId,
          wifiName: !wifiName ? null : wifiName,
          wifiPassword: !wifiPassword ? null : wifiPassword,
          accessInstructionsEn: !accessInstructionsEn
            ? null
            : accessInstructionsEn,
          accessInstructionsEs: !accessInstructionsEs
            ? null
            : accessInstructionsEs,
          accessInstructionsPt: !accessInstructionsPt
            ? null
            : accessInstructionsPt,
          groupId: !groupId ? null : groupId,
          groupName: groupName,
          groupEmailAddress: groupEmailAddress,
          groupPassword: groupPassword,
          vat: !vat ? null : vat,
          fullDayPrice: !fullDayPrice ? null : fullDayPrice,
          halfDayPrice: !halfDayPrice ? null : halfDayPrice,
          unredeemedPrice: !unredeemedPrice ? null : unredeemedPrice,
          meetingRoomCommission: !meetingRoomCommission
            ? null
            : meetingRoomCommission,
          privateOfficeCommission: !privateOfficeCommission
            ? null
            : privateOfficeCommission,
          images: state.images,
          schedule: schedule,
          amenities: state.amenities,
          appendToAnExistingGroup: appendToAnExistingGroup,
          businessEmail: businessEmail,
          businessPhone: businessPhone,
          notes: notes,
          automaticEmailReceivers: automaticEmailReceivers,
          automaticMessageByWhatsappReceivers:
            automaticMessageByWhatsappReceivers,
          metaTitle: metaTitle,
          metaDescription: metaDescription,
          mealIncluded: mealIncluded,
        };
        dispatch({
          type: Actions.SetSpaceDetails,
          id: id,
          spaceDetails: details,
        });

        var serverResponse;
        if (id == null) {
          serverResponse = await SpacesService.addSpace(details).then(
            (response) => response
          );
        } else {
          serverResponse = await SpacesService.updateSpace(id, details).then(
            (response) => response
          );
        }

        var errors = getServerSideErrors(serverResponse);
        var hasServerSideErrors = !errors ? false : true;
        dispatch({
          type: Actions.SetErrors,
          hasServerSideErrors: hasServerSideErrors,
          errors: errors,
        });
      },
  };

  const initialState: IState = {
    loading: false,

    id: null,
    name: "",
    categoryId: "",
    address: "",
    countryId: "",
    cityId: "",
    lat: null,
    lng: null,
    currency: "",
    spaceOffersHotDesks: true,
    requireBooking: true,
    maxSeats: null,
    descriptionEn: "",
    descriptionEs: "",
    descriptionPt: "",
    email: "",
    phone: "",
    localeId: "",
    wifiName: "",
    wifiPassword: "",
    accessInstructionsEn: "",
    accessInstructionsEs: "",
    accessInstructionsPt: "",
    groupId: "",
    groupName: "",
    groupEmailAddress: "",
    groupPassword: "",
    vat: null,
    fullDayPrice: null,
    halfDayPrice: null,
    unredeemedPrice: null,
    meetingRoomCommission: null,
    privateOfficeCommission: null,
    schedule: new Array(7).fill(undefined).map((e, i) => {
      return {
        openHour: null,
        closeHour: null,
        isClosed: false,
      } as IWorkspaceSchedule;
    }),
    images: [],
    amenities: [],
    businessEmail: "",
    businessPhone: "",
    notes: "",
    automaticEmailReceivers: [],
    automaticMessageByWhatsappReceivers: [],
    metaTitle: "",
    metaDescription: "",
    mealIncluded: false,

    hasServerSideErrors: null,
    errors: null,
  };

  export const reducer: Reducer<IState> = (
    currentState: IState,
    incomingAction: Action
  ) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
      case Actions.Initialize:
        return initialState;
      case Actions.ToggleLoading:
        return { ...currentState, loading: action.loading };
      case Actions.SetSpaceDetails:
        var scheduleClone = action.spaceDetails.schedule?.map((e, i) => {
          e =
            e == null
              ? ({
                  openHour: null,
                  closeHour: null,
                  isClosed: true,
                } as IWorkspaceSchedule)
              : e;
          return e;
        });
        return update(currentState, {
          id: { $set: action.id },
          name: { $set: action.spaceDetails.name },
          categoryId: { $set: action.spaceDetails.categoryId },
          address: { $set: action.spaceDetails.address },
          countryId: { $set: action.spaceDetails.countryId },
          cityId: { $set: action.spaceDetails.cityId },
          lat: { $set: action.spaceDetails.lat },
          lng: { $set: action.spaceDetails.lng },
          currency: { $set: action.spaceDetails.currency },
          spaceOffersHotDesks: {
            $set: action.spaceDetails.maxSeats == 0 ? false : true,
          },
          requireBooking: { $set: action.spaceDetails.requireBooking },
          maxSeats: { $set: action.spaceDetails.maxSeats },
          descriptionEn: { $set: action.spaceDetails.descriptionEn },
          descriptionEs: { $set: action.spaceDetails.descriptionEs },
          descriptionPt: { $set: action.spaceDetails.descriptionPt },
          email: { $set: action.spaceDetails.email },
          phone: { $set: action.spaceDetails.phone },
          localeId: { $set: action.spaceDetails.localeId },
          wifiName: { $set: action.spaceDetails.wifiName },
          wifiPassword: { $set: action.spaceDetails.wifiPassword },
          accessInstructionsEn: {
            $set: action.spaceDetails.accessInstructionsEn,
          },
          accessInstructionsEs: {
            $set: action.spaceDetails.accessInstructionsEs,
          },
          accessInstructionsPt: {
            $set: action.spaceDetails.accessInstructionsPt,
          },
          groupId: { $set: action.spaceDetails.groupId },
          groupName: { $set: action.spaceDetails.groupName },
          groupEmailAddress: { $set: action.spaceDetails.groupEmailAddress },
          groupPassword: { $set: action.spaceDetails.groupPassword },
          vat: { $set: action.spaceDetails.vat },
          fullDayPrice: { $set: action.spaceDetails.fullDayPrice },
          halfDayPrice: { $set: action.spaceDetails.halfDayPrice },
          unredeemedPrice: { $set: action.spaceDetails.unredeemedPrice },
          meetingRoomCommission: {
            $set: action.spaceDetails.meetingRoomCommission,
          },
          privateOfficeCommission: {
            $set: action.spaceDetails.privateOfficeCommission,
          },
          businessEmail: { $set: action.spaceDetails.businessEmail },
          businessPhone: { $set: action.spaceDetails.businessPhone },
          notes: { $set: action.spaceDetails.notes },
          automaticEmailReceivers: {
            $set: action.spaceDetails.automaticEmailReceivers,
          },
          automaticMessageByWhatsappReceivers: {
            $set: action.spaceDetails.automaticMessageByWhatsappReceivers,
          },
          metaTitle: { $set: action.spaceDetails.metaTitle },
          metaDescription: { $set: action.spaceDetails.metaDescription },
          mealIncluded: { $set: action.spaceDetails.mealIncluded },
          schedule: { $set: scheduleClone },
          amenities: { $set: action.spaceDetails.amenities },
          images: { $set: action.spaceDetails.images },
        });
      case Actions.SetSpaceImages:
        return { ...currentState, images: action.images };
      case Actions.SetSpaceSchedule:
        return { ...currentState, schedule: action.schedule };
      case Actions.SetSpaceAmenities:
        return { ...currentState, amenities: action.amenities };
      case Actions.SetErrors:
        return update(currentState, {
          hasServerSideErrors: { $set: action.hasServerSideErrors },
          errors: { $set: action.errors },
        });
      default:
        return currentState || initialState;
    }
  };
}
