import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "antd";
import bind from "bind-decorator";
import update from "immutability-helper";

import { ApplicationState } from "../../store";
import PaginationBar from "../Shared/PaginationBar";
import General from "../../resources/AdminUI/General";
import { RoomBookingsItem } from "../../models/RoomBookings/RoomBookingsItem";
import { RoomBookingsGridStore } from "../../store/RoomBookingsGridStore";

import {
  firstLetterToLowerCase,
  getPromiseFromAction,
} from "../../utils/utils";
import IPageIndex from "../../models/Pagination/IPageIndex";
import { NotificationStore } from "../../store/NotificationStore";
import RoomBookingsService from "../../services/RoomBookingsService";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { RoomBookingRejectReasons } from "../../enums/RoomBookings/RoomBookingRejectReasons";
import RoomBookingRejectReasonList from "../../resources/AdminUI/RoomBookings/RoomBookingRejectReasonList";

import ErrorMessages from "../../resources/Common/ErrorMessages";
import { NavigateFunction } from "react-router";
import { withRouter } from "../Shared/withRouter";
import Loading from "../Shared/Loading";
import RoomBookingsTableBody from "./RoomBookingsTableBody";
import RoomBookingsTableHead from "./RoomBookingsTableHead";
const { confirm } = Modal;

interface IProps {
  roomBookings?: RoomBookingsItem[];
  loading?: boolean;
  hiddenColumns?: string[];
  expandableColumns?: string[];
  isRoomBookingHiddenList: boolean[];
  filters?: string;
  numberOfRoomBookings?: number;
  itemsPerPage?: number;
  firstIndexFromPage?: number;
  lastIndexFromPage?: number;
  pageIndexArray?: IPageIndex[];
  showActionsColumn?: boolean;
  showPendingActionsColumn?: boolean;
  navigate?: NavigateFunction;
  reload?: () => void;
  changeCurrentPage?: (currentPage: number) => void;
  initialize?: (
    defaultSelectedItemsPerPageOption: number,
    filters: string
  ) => void;
  initializePendingRoomRequestsPage?: (
    defaultSelectedItemsPerPageOption: number
  ) => void;
  showErrorNotification?: () => void;
}
interface IState {
  isRoomBookingHiddenList: boolean[];
  isDeclineModalOpen: boolean;
  selectedRoomBooking?: RoomBookingsItem;
}
class RoomBookingsTable extends React.PureComponent<any, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isRoomBookingHiddenList: [],
      isDeclineModalOpen: false,
    };
  }
  public static defaultProps: Partial<IProps> = {
    loading: false,
  };

  tableClass: string = "table";
  itemsPerPage: number = 15;
  maxPagesDisplayed: number = 3;
  pageNeighbours: number = 1;
  hasRefreshButton: boolean = false;

  @bind
  toggleIsExpanded(index) {
    this.setState(
      update(this.state, {
        isRoomBookingHiddenList: {
          [index]: { $set: !this.state.isRoomBookingHiddenList[index] },
        },
      })
    );
  }

  @bind
  changeCurrentPage(currentPage: number) {
    this.props.changeCurrentPage(currentPage);
    this.setState({
      isRoomBookingHiddenList: this.props.isRoomBookingHiddenList,
    });
  }

  @bind
  showAcceptRoomBookingModal(id) {
    confirm({
      title: RoomBookingsGrid.Resources.acceptRoomRequest,
      content: (
        <div>
          <p>
            {RoomBookingsGrid.Resources.acceptRoomRequestConfirmationMessage}
          </p>
        </div>
      ),
      okText: RoomBookingsGrid.Resources.accept,
      okType: "primary",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        this.acceptRoomBooking(id);
      },
      onCancel: () => {},
    });
  }

  @bind
  async acceptRoomBooking(id) {
    await RoomBookingsService.acceptRoomBooking(id).then((res) => {
      if (!res || res.hasErrors) {
        Modal.error({
          title: ErrorMessages.Resources.generalErrorMessage,
        });
      } else {
        this.props.reload();
      }
    });
  }

  @bind
  hideDeclineRoomBookingModal() {
    this.setState({ isDeclineModalOpen: false });
  }

  @bind
  showDeclineRoomBookingModal(booking: RoomBookingsItem) {
    this.setState({ isDeclineModalOpen: true, selectedRoomBooking: booking });
  }

  @bind
  getDeclineReasonsList() {
    var options = [];
    let keys = Object.keys(RoomBookingRejectReasons).filter(
      (k) => typeof RoomBookingRejectReasons[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = RoomBookingRejectReasons[key as any].toString();
      options.push({
        value: value,
        label:
          RoomBookingRejectReasonList.Resources[firstLetterToLowerCase(key)],
      });
    });
    return options;
  }

  @bind
  async declineRoomBooking(id, reasonId) {
    await RoomBookingsService.rejectRoomBooking(id, reasonId).then((res) => {
      if (!res || res.hasErrors) {
        Modal.error({
          title: ErrorMessages.Resources.generalErrorMessage,
        });
      } else {
        this.props.reload();
      }
    });
  }

  componentDidMount(): void {
    if (
      !this.props.roomBookings ||
      (this.props.roomBookings.length == 0 && !this.props.filters)
    ) {
      getPromiseFromAction(
        this.props.initialize(this.itemsPerPage, this.props.filters)
      ).then(() =>
        this.setState({
          isRoomBookingHiddenList: this.props.isRoomBookingHiddenList,
        })
      );
    } else {
      getPromiseFromAction(this.props.reload()).then(() =>
        this.setState({
          isRoomBookingHiddenList: this.props.isRoomBookingHiddenList,
        })
      );
    }
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    if (!this.props.roomBookings || this.props.roomBookings.length == 0) {
      return <div>{General.Resources.noElements}</div>;
    } else {
      return (
        <div>
          <div className="table-wrapper">
            <table className={this.tableClass}>
              <RoomBookingsTableHead
                roomBookings={this.props.roomBookings}
                hiddenColumns={this.props.hiddenColumns}
                showActionsColumn={this.props.showActionsColumn}
                showPendingActionsColumn={this.props.showPendingActionsColumn}
              />
              <RoomBookingsTableBody
                roomBookings={this.props.roomBookings}
                hiddenColumns={this.props.hiddenColumns}
                showActionsColumn={this.props.showActionsColumn}
                showPendingActionsColumn={this.props.showPendingActionsColumn}
                expandableColumns={this.props.expandableColumns}
                isRoomBookingHiddenList={this.state.isRoomBookingHiddenList}
                selectedRoomBooking={this.state.selectedRoomBooking}
                hasHeader={false}
                isDeclineModalOpen={this.state.isDeclineModalOpen}
                toggleIsExpanded={this.toggleIsExpanded}
                showDeclineRoomBookingModal={this.showDeclineRoomBookingModal}
                hideDeclineRoomBookingModal={this.hideDeclineRoomBookingModal}
                declineRoomBooking={this.declineRoomBooking}
                getDeclineReasonsList={this.getDeclineReasonsList}
                showAcceptRoomBookingModal={this.showAcceptRoomBookingModal}
              />
            </table>
          </div>
          <PaginationBar
            totalItems={this.props.numberOfRoomBookings}
            reload={this.props.reload}
            changeCurrentPage={this.changeCurrentPage}
            firstIndexFromPage={this.props.firstIndexFromPage}
            lastIndexFromPage={this.props.lastIndexFromPage}
            pageIndexArray={this.props.pageIndexArray}
            maxPagesDisplayed={this.maxPagesDisplayed}
            pageNeighbours={this.pageNeighbours}
          />
        </div>
      );
    }
  }
}

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: any) => {
      return state.roomBookingsGrid[ownProps.componentId] || {};
    },
    (dispatch, ownProps: any) =>
      bindActionCreators(
        {
          ...RoomBookingsGridStore.getActionCreators(ownProps.componentId),
          showErrorNotification:
            NotificationStore.actionCreators.showErrorNotification,
        },
        dispatch
      )
  )(RoomBookingsTable)
);
