import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { TabStore } from "../../../store/TabStore";
import { TabsHeader } from "../../Tabs/TabsHeader";
import General from "../../../resources/AdminUI/General";
import { ITabItem, ITabTitleItem } from "../../../utils/reactUtils";
import HotdeskBookingsTable from "../../HotdeskBookings/HotdeskBookingsTable";
import RoomBookingsTable from "../../RoomBookings/RoomBookingsTable";
import { BookingLogTypes } from "../../../enums/Common/BookingLogTypes";
import HotdeskBookingsGrid from "../../../resources/AdminUI/HotdeskBookings/HotdeskBookingsGrid";
import RoomBookingsGrid from "../../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { bindActionCreators } from "redux";


class UsersBookingsTablesTabComponent extends React.PureComponent<any, any>{
    constructor(props) {
        super(props);
        var tabs = [
            {
                tabId: BookingLogTypes.HotdeskLogs.toString(),
                title: HotdeskBookingsGrid.Resources.desksLogs,
                class: '',
                content: () => <HotdeskBookingsTable
                    componentId={`user-hotdesk-bookings-${this.props.userId}`}
                    showActionsColumn={this.props.showActionsColumn}
                    filters={this.props.hotdeskBookingsTableFilters}
                    hiddenColumns={this.props.hotdeskBookingsTableHiddenColumns}
                    expandableColumns={this.props.hotdeskBookingsTableExpandableColumns} />
            } as ITabItem,
            {
                tabId: BookingLogTypes.RoomLogs.toString(),
                title: RoomBookingsGrid.Resources.roomLogs,
                class: '',
                content: () => <RoomBookingsTable
                    componentId={`user-room-bookings-${this.props.userId}`}
                    filters={this.props.roomBookingsTableFilters}
                    hiddenColumns={this.props.roomBookingsTableHiddenColumns}
                    expandableColumns={this.props.roomBookingsTableExpandableColumns} />
            } as ITabItem
        ];

        this.props.initializeTabPage(tabs, BookingLogTypes.HotdeskLogs.toString(), null);
    }

    render() {
        {
            if (this.props.tabState == undefined) {
                return <div>
                    <h2 className="text-center">{General.Resources.loading}</h2>
                </div>
            }
        }
        var activeTab = this.props.tabState.tabs.filter(t => t.tabId == this.props.tabState.activeTabId)[0];

        return <div>
            <TabsHeader onTabClick={this.props.changeActiveTab}
                activeTabId={activeTab.tabId}
                className={"section-table-tabs"}
                tabs={this.props.tabState.tabs.map(
                    t => ({ title: t.title, tabId: t.tabId, class: t.class } as ITabTitleItem))} 
            />
            <div>
                {activeTab.content()}
            </div>
            
        </div>
    }
}


export default connect(
    (state: ApplicationState, ownProps: any) => {
        return { tabState: state.tab[ownProps.componentId] }
    },
    (dispatch, ownProps: any) => bindActionCreators({
        ...TabStore.getActionCreators(ownProps.componentId)
    }, dispatch)
)(UsersBookingsTablesTabComponent);
