import React from "react";
import Helmet from 'react-helmet';
import SpaceGroupsContainer from "../../components/ManageAccounts/Containers/SpaceGroupsContainer";
import SpaceGroupAccountsContainer from "../../components/ManageAccounts/Containers/SpaceGroupAccountsContainer";
import { connect } from "react-redux";
import { ManageAccountStore } from "../../store/ManageAccountsStore";
import ManageAccounts from "../../resources/AdminUI/ManageAccounts";
import { TabsHeader } from "../../components/Tabs/TabsHeader";
import { ApplicationState } from "../../store";
import CompaniesContainer from "../../components/ManageAccounts/Containers/CompaniesContainer";
import CompanyAccountsContainer from "../../components/ManageAccounts/Containers/CompanyAccountsContainer";

interface IProps {
  initializeSpaceGroupPage?: () => void
  initializeCompaniesPage?: () => void
}

class ManageAccountsPage extends React.PureComponent<IProps, any> {
    constructor(props) {
        super(props);

        this.props.initializeSpaceGroupPage();
        this.state = {
          tabs: [
            {
              tabId: '1',
              title: 'Space accounts',
              class: '',
              content: () => (
                <div className="lists-container accounts-page">
                    <div className="left-list-container">
                        <SpaceGroupsContainer />
                    </div>
                    <div className="right-list-container">
                        <SpaceGroupAccountsContainer />
                    </div>
                </div>
              )
            },
            {
              tabId: '2',
              title: 'Customer accounts',
              class: '',
              content: () => (
                <div className="lists-container accounts-page">
                    <div className="left-list-container">
                        <CompaniesContainer />
                    </div>
                    <div className="right-list-container">
                        <CompanyAccountsContainer />
                    </div>
                </div>
              ) 
            }
          ],
          activeTabId: '1'
        }
    }

    render() {
        return <div>

            <div className="page-top-container">
                <div className="page-title">{ManageAccounts.Resources.manageAccountsHeader}</div>
             </div>

            <Helmet title={ManageAccounts.Resources.manageAccountsHeader}></Helmet>

            <TabsHeader onTabClick={(tabId) => {
                  this.setState({ activeTabId: tabId })
                  tabId === '1' ? this.props.initializeSpaceGroupPage() : this.props.initializeCompaniesPage()
                }}
                activeTabId={this.state.activeTabId}
                className={"section-table-tabs"}
                tabs={this.state.tabs.map(
                    t => ({ title: t.title, tabId: t.tabId, class: t.class }))} 
            />

            {this.state.tabs.filter(t => t.tabId == this.state.activeTabId)[0].content()}

            
        </div>
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => {
    return { tabState: state.tab[ownProps.componentId] }
    },
    {
        initializeSpaceGroupPage: ManageAccountStore.actionCreators.initializeSpaceGroupPage,
        initializeCompaniesPage: ManageAccountStore.actionCreators.initializeCompaniesPage
    }
)(ManageAccountsPage as any)