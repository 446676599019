import React from "react";
import bind from "bind-decorator";
import { connect } from "react-redux";
import { String_Format } from "../../utils/utils";
import DropdownButton from "../../components/Shared/DropdownButton";
import { UserDetailsCard } from "../../components/Users/Cards/UserDetailsCard";
import { UserDetailsStore } from "../../store/UserDetailsStore";
import { ApplicationState } from "../../store";
import Helmet from "react-helmet";
import UserDetails from "../../resources/AdminUI/Users/UserDetails";
import General from "../../resources/AdminUI/General";
import AddOrEditUserForm from "../../components/Users/Forms/AddOrEditUserForm";
import { UserStates } from "../../enums/Users/UserStates";
import UsersBookingsTablesTabComponent from "../../components/Users/Tabs/UsersBookingsTablesTabComponent";
import UserBenefitsContainer from "../../components/Users/Containers/UserBenefitsContainer";
import { NavigateFunction } from "react-router-dom";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { withRouter } from "../../components/Shared/withRouter";
import ModalDialog from "../../components/Shared/ModalDialog";
import { BenefitsStore } from "../../store/BenefitsStore";
import Loading from "../../components/Shared/Loading";
const { confirm } = Modal;

interface IProps {
  navigate?: NavigateFunction;
}

class UserDetailsPage extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      isEditUserModalOpen: false,
    };

    if (props.params.has("id")) {
      var userId = parseInt(props.params.get("id"));

      this.props.initializeBenefits();
      this.props.getUserDetails(userId);
    }
  }

  tableFilterTemplate = `UserId eq {0}`;

  @bind
  showEditUserModal() {
    this.setState({ isEditUserModalOpen: true });
  }

  @bind
  hideEditUserModal() {
    this.setState({ isEditUserModalOpen: false });
  }

  @bind
  showDeactivateUserModal() {
    confirm({
      title: General.Resources.areYouSureModalLabel,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{UserDetails.Resources.deactivateUserDescLabel}</p>
        </div>
      ),
      okText: General.Resources.deactivateLabel,
      okType: "danger",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        this.handleOnDeactivateUser();
      },
      onCancel() {},
    });
  }

  @bind
  showDeleteUserModal() {
    confirm({
      title: General.Resources.areYouSureModalLabel,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{UserDetails.Resources.deleteUserDescLabel}</p>
        </div>
      ),
      okText: General.Resources.deleteLabel,
      okType: "danger",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        this.handleOnDeleteUser();
      },
      onCancel() {},
    });
  }

  @bind
  handleOnActivateUser() {
    this.props.activateUser(this.props.user.id);
  }

  @bind
  handleOnDeactivateUser() {
    this.props.deactivateUser(this.props.user.id);
  }

  @bind
  handleOnDeleteUser() {
    this.props.deleteUser(this.props.user.id);
  }

  @bind
  getAvailableActions() {
    var moreActionsButtonItems = [];

    if (this.props.user.status === UserStates.Active) {
      moreActionsButtonItems.push({
        icon: "toggle_off",
        text: UserDetails.Resources.deactivateUserOptionLabel,
        action: this.showDeactivateUserModal,
      });
    }

    if (this.props.user.status === UserStates.Deactivated) {
      moreActionsButtonItems.push({
        icon: "toggle_on",
        text: UserDetails.Resources.activateUserOptionLabel,
        action: this.handleOnActivateUser,
      });
    }

    moreActionsButtonItems.push({
      icon: "delete",
      text: UserDetails.Resources.deleteUserOptionLabel,
      action: this.showDeleteUserModal,
    });

    return moreActionsButtonItems;
  }

  render() {
    if (this.props.loading || !this.props.user) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else {
      return (
        <div>
          <Helmet title={UserDetails.Resources.userDetailsHeader}></Helmet>

          <div className="details-page-top">
            <div className="page-navigation">
              <span
                className="material-symbols-outlined"
                onClick={() => this.props.navigate(-1)}
              >
                arrow_back
              </span>
              <div className="page-title">
                {UserDetails.Resources.userDetailsHeader}
              </div>
            </div>

            <div className="page-actions">
              {this.props.user.status != UserStates.Deleted && (
                <DropdownButton
                  text={General.Resources.moreActions}
                  items={this.getAvailableActions()}
                  className="btn-big btn-secondary"
                  icon="arrow_drop_down"
                />
              )}

              <button
                className="btn-big btn-primary"
                onClick={this.showEditUserModal}
              >
                <span className="material-symbols-outlined">edit</span>
                <p className="btn-primary-text">
                  {UserDetails.Resources.editUserInfoLabel}
                </p>
              </button>
              <ModalDialog
                open={this.state.isEditUserModalOpen}
                onCancel={this.hideEditUserModal}
              >
                <AddOrEditUserForm
                  id={this.props.user.id}
                  firstName={this.props.user.firstName}
                  lastName={this.props.user.lastName}
                  phoneNo={this.props.user.phone}
                  email={this.props.user.email}
                  companyId={this.props.user.companyId}
                  internalMetadata={this.props.user.internalMetadata}
                  onCancelCallback={this.hideEditUserModal}
                />
              </ModalDialog>
            </div>
          </div>

          <UserDetailsCard user={this.props.user} />

          <section className="container">
            <UserBenefitsContainer
              userId={this.props.user.id}
              userName={
                this.props.user.lastName + " " + this.props.user.firstName
              }
            />
          </section>

          <section className="container">
            <UsersBookingsTablesTabComponent
              componentId={`user-bookings-${this.props.user.id}`}
              userId={this.props.user.id}
              hotdeskBookingsTableFilters={String_Format(
                this.tableFilterTemplate,
                this.props.user.id
              )}
              hotdeskBookingsTableHiddenColumns={[
                "userEmail",
                "userPhone",
                "statusName",
                "userFirstName",
                "userLastName",
                "userCompanyName",
                "workspaceName",
                "workspaceCategoryId",
                "workspaceCityId",
                "workspaceCityName",
                "workspaceCountryId",
                "workspaceCountryName",
                "workspaceCountryFlag",
                "timeSpent",
                "isFullDay",
                "createdAt",
                "checkinAt",
                "checkoutAt",
                "expiresAt",
                "cancelledAt",
                "bookingAt",
                "timezone",
                "isWalkin",
                "isAutomatedCheckout",
                "unixBookingAtWithTimezone",
              ]}
              hotdeskBookingsTableExpandableColumns={["createdAt"]}
              showActionsColumn={true}
              roomBookingsTableFilters={String_Format(
                this.tableFilterTemplate,
                this.props.user.id
              )}
              roomBookingsTableHiddenColumns={[
                "statusName",
                "userLastName",
                "userId",
                "userEmail",
                "userPhone",
                "userCompanyName",
                "roomName",
                "workspaceName",
                "workspaceCityId",
                "workspaceCityName",
                "workspaceCountryId",
                "workspaceCountryName",
                "workspaceCountryFlag",
                "currency",
                "bookingAmount",
                "timezone",
                "unixStartsAtWithTimezone",
                "startsAt",
                "endsAt",
                "expiresAt",
                "cancelledAt",
                "rejectedAt",
              ]}
              roomBookingsTableExpandableColumns={["userId"]}
            ></UsersBookingsTablesTabComponent>
          </section>
        </div>
      );
    }
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        user: state.userDetails.user,
        loading: state.userDetails.loading,
      };
    },
    {
      initializeBenefits: BenefitsStore.actionCreators.inititalizeBenefits,
      getUserDetails: UserDetailsStore.actionCreators.getUserDetails,
      activateUser: UserDetailsStore.actionCreators.activateUser,
      deactivateUser: UserDetailsStore.actionCreators.deactivateUser,
      deleteUser: UserDetailsStore.actionCreators.deleteUser,
    }
  )(UserDetailsPage)
);
