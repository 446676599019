import { ServiceBase } from "./ServiceBase";
import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { EntitiesList, IEntitiesList } from "../models/Common/IEntitiesList";
import { IHotdeskBookingsItem } from "../models/HotdeskBookings/Interfaces/IHotdeskBookingsItem";
import { HotdeskBookingsItem } from "../models/HotdeskBookings/HotdeskBookingsItem";
import { PayloadTypes } from "../enums/Common/PayloadTypes";

export default class HotdeskBookingsService extends ServiceBase {

    static getHotdeskBookings = (toTake: number, toSkip: number, filters: string): Promise<Result<IEntitiesList<IHotdeskBookingsItem>>> => {

        var url = `/odata/hotdesk-bookings/?$top=${toTake}&$skip=${toSkip}`;

        if (filters && filters.length > 0) {
            url = `${url}&$filter=${filters}`;
        }

        try {
            var result = HotdeskBookingsService.requestJsonFetch<IEntitiesList<IHotdeskBookingsItem>>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}${url}&$orderby=Id desc&$count=true`
            });
            return result;
        } catch (error) {
        }

        return new Promise<Result<IEntitiesList<IHotdeskBookingsItem>>>(null);

    }

    static additionalMapping = (entitiesList: IEntitiesList<IHotdeskBookingsItem>): EntitiesList<IHotdeskBookingsItem> => {
        var newEntities = new EntitiesList<IHotdeskBookingsItem>();
        newEntities.entities = entitiesList.entities.map((e) => new HotdeskBookingsItem(e));
        newEntities.numberOfEntities = entitiesList.numberOfEntities;
        return newEntities;
    }

    static getHotdeskBookingDetailsForEdit = (id: number): Promise<Result<any>> => {

        try {
            var result = HotdeskBookingsService.requestJsonFetch<any>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/hotdesk-bookings/${id}/form-details`
            });
            return result;
        } catch (error) {
        }

        return new Promise<Result<any>>(null);

    }

    
    static addHotdeskBooking = (hotdeskBookingDetails: any):  Promise<Result<any>> => {
        
        var result = HotdeskBookingsService.sendData<any>({
            method: "POST",
            url: `${AppSettings.CommonSettings.ApiUrl}/hotdesk-bookings`,
            data: JSON.stringify(hotdeskBookingDetails)
        }, PayloadTypes.Json);

        return result;
        
    }

    static updateHotdeskBooking = (id:number, hotdeskBookingDetails: any):  Promise<Result<any>> => {
        
        var result = HotdeskBookingsService.sendData<any>({
            method: "PUT",
            url: `${AppSettings.CommonSettings.ApiUrl}/hotdesk-bookings/${id}`,
            data: JSON.stringify(hotdeskBookingDetails)
        }, PayloadTypes.Json);

        return result;
        
    }

    static deleteHotdeskBooking = (id:number):  Promise<Result<any>> => {
        
        var result = HotdeskBookingsService.sendData<any>({
            method: "DELETE",
            url: `${AppSettings.CommonSettings.ApiUrl}/hotdesk-bookings/${id}`,
            data: null
        }, PayloadTypes.Json);

        return result;
        
    }

    static getHotdeskBookingsForExport = (filters?: string): Promise<Result<IEntitiesList<IHotdeskBookingsItem>>> => {

        var url = `/odata/hotdesk-bookings?$orderby=Id desc&$count=true`;

        if (filters && filters.length > 0) {
            url = `${url}&$filter=${filters}`;
        }

        try {
            var result = HotdeskBookingsService.requestJsonFetch<IEntitiesList<IHotdeskBookingsItem>>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}${url}`
            });
            return result;
        } catch (error) {
        }

        return new Promise<Result<IEntitiesList<IHotdeskBookingsItem>>>(null);

    }

}